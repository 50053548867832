import React, {Component} from 'react';
import {TimelineMax} from 'gsap';

class Tagline extends Component
{
    constructor(props) {
        super(props)
        this.tl = new TimelineMax()
        this.lines = []
    }

    componentDidMount() {
        window.setTimeout(()=>{
            this.tl.to(this.refs.container, 0.4, {opacity:1})
            this.lines.map(line=>this.tl.from(line, 0.4, {opacity:0,left:300})
            .to(line, 0.4, {opacity:1, left:0, position:'relative'}))
        }, 2000)
    }

    render() {
        return <div ref="container" style={{opacity:0}}>
            {this.props.data.data.map((line, index)=><div key={`line-${index}`} ref={div=>this.lines.push(div)} style={{position:"absolute"}}>{line}</div>)}
        </div>
    }
}

export default Tagline;