import React, {Component} from 'react';
import Ry, {store} from '../vendor/Ry/Core/Ry';
import $ from 'jquery';
import moment from 'moment';
import trans from 'ryapp/translations';

class NavItem2 extends Component
{	
	constructor(props) {
		super(props)
		this.submenu = this.submenu.bind(this)
	}
	
	submenu(e) {
		console.log("submenyuiiii");
		alert("ddddd");
        if(this.props.data.children.length==0)
            return true

        e.preventDefault()
        store.dispatch({type:'submenu', visible:true})
    }
	
    render() {
        return <li className={this.props.data.children.length>0?"dropdown-submenu":''}>
            <a className="dropdown-item" href={this.props.data.href} onClick={this.submenu}>{this.props.data.title}</a>
            {this.props.data.children.length>0?<ul className="dropdown-menu">
                <li className="back-button d-none d-lg-block">
                    <a className="dropdown-item">
                        Back
                    </a>
                </li>
                {this.props.data.children.map((item, index)=><NavItem2 pkey={`${this.props.pkey}-${index}`} key={`${this.props.pkey}-${index}`} data={item}/>)}
            </ul>:null}
        </li>
    }
}

class NavItem extends Component
{
    constructor(props) {
        super(props)
		this.state = {
			t : moment().format('YYYY-MM-DD-HH-mm-ss')
		}
    }

	handleClick(e) {
		console.log('hgghgh', e)
	}

    render() {
        return  <li className={this.props.data.children.length>0?'dropdown':''}>
            <a className={this.props.data.children.length>0?"dropdown-item dropdown-toggle":''} href={this.props.data.href} onClick={this.handleClick}>
                {this.props.data.title}</a>
            {this.props.data.children.length>0?<ul className="dropdown-menu">
                <li className="back-button d-none d-lg-block">
                    <a className="dropdown-item">
                        Back
                    </a>
                </li>
                {this.props.data.children.map((item, index)=><NavItem2 pkey={`${this.props.pkey}-${index}`} key={`${this.props.pkey}-${index}`} data={item}/>)}
            </ul>:null}
        </li>
    }
}

class Nav extends Component
{
    constructor(props) {
        super(props)
    }

    componentDidMount() {
		window.theme.Nav.initialize();
    }

    render() {
        return <ul className={`nav nav-pills`} id="mainNav">
            {this.props.data.map((item, index)=><NavItem pkey={`nav-${index}`} key={`nav-${index}`} data={item} store={store}/>)}
        </ul>
    }
}

export default Nav;