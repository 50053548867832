import React, {Component} from 'react';
import {store} from 'ryvendor/Ry/Core/Ry';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import $ from 'jquery';
import appstore_download from './images/download-app-store.png';
import appstore_qrcode from './images/app-store-qr-code.jpg';
import gopl_qrcode from './images/qrcode.png';

class Form extends Component
{
	constructor(props) {
		super(props)
		this.state = {
			human : false
		}
	}
	
	componentDidMount() {
		store.subscribe(()=>{
			const storeState = store.getState()
			if(storeState.type=='captcha') {
				$.ajax({
					type : 'post',
					url : '/human',
					data : {
						token : storeState.token
					},
					success : response => {
						if(response.success) {
							this.setState({
								human : true
							})
						}
					}
				})
			}
			else if(storeState.type=='asset') {
				this.setState({
					asset : storeState.row
				})
			}
		})
	}
	
	render() {
		if(this.state.asset) {
			return <div className="text-center">
				<h2>Votre QR Code Signato</h2>
				<img src={`/storage/assets/qr/${this.models('state.asset.nsetup.id')}`} className="img-fluid"/>
				<div className="text-center pt-3">
					<a href={`/assets/pdf?id=${this.models('state.asset.nsetup.id')}`} className="btn btn-secondary" target="_blank">Téléchargez votre badge</a>
					<a href={`/`} className="btn btn-primary ml-3">Nouveau badge</a>
				</div>
			</div>
		}
		return <div className="row">
			<div className="col-md-2 d-flex flex-column justify-content-center text-center">
				<img className="img-fluid mb-3" src={appstore_download}/><br/>
				<img className="img-fluid" src={appstore_qrcode}/>
			</div>
			<div className="col-md-2 d-flex flex-column justify-content-center text-center">
				<a href='https://play.google.com/store/apps/details?id=mg.azoantoka.signato&utm_source=azoantoka&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img className="img-fluid mb-3" alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/></a><br/>
				<img className="img-fluid" src={gopl_qrcode}/>
			</div>
			<div className="col-md-8">
				<form action="/assets/qr/generate" method="post" name="frm_asset">
					<h2 className="text-center">Signato</h2>
		    		<div className="row">
		    			<div className="col-md-6 form-group">
		    				<label className="control-label">Nom</label>
		    				<input type="text" required className="form-control" name="row[0]"/>
		    			</div>
		    			<div className="col-md-6 form-group">
		    				<label className="control-label">Prénom</label>
		    				<input type="text" required className="form-control" name="row[1]"/>
		    			</div>
		    			<div className="col-md-12 form-group">
		    				<label className="control-label">Adresse</label>
		    				<input type="text" required className="form-control" name="row[2]"/>
		    			</div>
		    			<div className="col-md-6 form-group">
		    				<label className="control-label">Ville</label>
		    				<input type="text" required className="form-control" name="row[3]"/>
		    			</div>
		    			<div className="col-md-6 form-group">
		        			<label className="control-label">Téléphone</label>
		        			<input type="text" required className="form-control" name="row[4]"/>
		        		</div>
						<input type="hidden" name="ry"/>
		    		</div>
		    		{this.state.human?<div className="text-center">
		    			<button className="btn btn-primary">Valider</button>
		    		</div>:null}
		    	</form>
			</div>
		</div>
	}
}

export default Modelizer(Form)