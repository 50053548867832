import React, {Component} from 'react';
import $ from 'jquery';
import logo_circle from '../../medias/images/logo-circle.png';
import trans from 'ryapp/translations';
import Counter from './Counter';

class Form extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            search : '',
            proposed : false,
            data : {
                data : []
            }
        }
        this.propose = this.propose.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    handleSearch(e) {
        this.setState({
            search : e.target.value
        })
        $(this.refs.frm_search).submit()
    }

    propose() {
        this.setState({
            proposed : !this.state.proposed && this.state.data.data.length>0
        })
    }

    componentDidMount() {
        window.setTimeout(()=>{
            $(this.refs.search).focus()
        }, 0)
        this.props.store.subscribe(()=>{
            const storeState = this.props.store.getState()
            if(storeState.type=='search') {
                this.setState(state=>{
                    state.proposed = true
                    state.data = storeState.data
                    return state
                })
            }
            if(storeState.type=='investigate') {
                $.ajax({
                    url : '/investigate',
                    data : {
                        q : this.state.search
                    },
                    success : response=>{
                        
                    }
                })
            }
        })
    }

    render() {
        return <div className="col-md-6 text-center">
            <form action="/search" ref="frm_search">
                <input type="hidden" name="ry"/>
                <input type="hidden" name="json"/>
                <img alt="Azo Antoka" width="120" height="120" src={logo_circle} className="mb-3"/>
                <div className="form-group position-relative">
                    <div className="input-group">
                        <input ref="search" name="q" className="form-control" placeholder={trans('Rechercher')} autoComplete="off" onClick={this.propose} onChange={this.handleSearch}/>
                        <div className="input-group-append">
                            <span className="bg-transparent border-0 input-group-text text-light"><i className="fa fa-shield-alt fa-2x"></i></span>
                        </div>
                    </div>
                    <div className={`dropdown-menu ${this.state.proposed?'show':''} w-100`}>
                        {this.state.data.data.length==0?<Counter search={this.state.search} store={this.props.store}/>:this.state.data.data.map(result=><a key={`result-${result.id}`} className="d-flex dropdown-item justify-content-between" href={result.href}>
                        <span>{result.title}</span>
                        <span className="small text-muted"><i className="fa fa-check text-success mr-2"></i>{trans('sure')} {result.percent}%</span>
                        </a>)}
                        <div className="dropdown-divider"></div>
                        <div className="justify-content-between mx-2 row">
                            <button className="btn btn-primary">{trans('Recherche Azo Antoka')}</button>
                            <button className="btn btn-secondary">{trans('Rendre Azo Antoka')}</button>
                        </div>
                    </div>
                </div>
                <button className="btn btn-outline-primary" tabIndex="-1">Azo ?</button>
            </form>
        </div>
    }
}

export default Form;