import React, {Component} from 'react';
import Ry, {store} from '../vendor/Ry/Core/Ry';
import Core from '../vendor/Ry/Core/Core';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import './aa19.scss';
import './porto';
import Search from './Search';
import Fun from './Fun';
import '../medias/images/logo-circle.png';
import '../medias/apple-touch-icon.png';
import '../medias/favicon-32x32.png';
import '../medias/favicon-16x16.png';
import '../medias/safari-pinned-tab.svg';
import '../medias/safari-pinned-tab.svg';
import '../medias/safari-pinned-tab.svg';
import Nav from './Nav';
import Signato from '../themes/signato';

const Components = {
    Core : Core,
    Search : Search,
    Fun : Fun,
    Nav : Nav,
	Signato
}

class Fbcore extends Component
{
    componentDidMount() {
        window.fbAsyncInit = function() {
			FB.init({
			appId            : '783671475817328',
			autoLogAppEvents : true,
			xfbml            : true,
			version          : 'v10.0'
			});
        };
        $.getScript('https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js');
    }

    render() {
        return <React.Fragment>
            <Ry class="Core" components={this.props.components}/>
            <div id="fb-root"></div>
            <div className="fb-customerchat" page_id="102069381179600" logged_out_greeting="Sommes-nous amis ?"></div>
        </React.Fragment>
    }
}

class AA19 {
    constructor() {
        this.$ = $;
        this.bootstrap();
        const overlay = $('<div style="position: absolute; top: 0"><div></div></div>');
		$("body").append(overlay);
		ReactDOM.render(<Fbcore class="Core" components={Components}/>, overlay[0]);
    }

    bootstrap() {
		$.getScript('https://www.google.com/recaptcha/api.js?render=6Le464QbAAAAAGRN4Ezxx01RqcapX9oLB2LAJ3MO', ()=>{
			grecaptcha.ready(function() {
	          grecaptcha.execute('6Le464QbAAAAAGRN4Ezxx01RqcapX9oLB2LAJ3MO', {action: 'submit'}).then(function(token) {
				store.dispatch({type:'captcha', human:true, token})
	          });
	        });
		});
        if($('.tagline').length>0) {
            let lines = []
            $('.tagline li').each(function(){
                lines.push($(this).text())
            })
            ReactDOM.render(<Ry class="Fun.Tagline" content={{data:lines}} components={Components}/>, $('.tagline')[0])
        }
        const treeIze = function(ar){
            let json = []
            ar.each(function(){
                json.push({
                    title : $(this).children('a').html(),
                    href : $(this).children('a').attr('href'),
                    children : treeIze($(this).children('ul').children('li'))
                })
            })
            return json
        }
        $('nav').each(function(){
            const tree = treeIze($('nav>ul>li'))
            ReactDOM.render(<Ry class="Nav" content={tree} components={Components}/>, $(this)[0])
        });
        $("script[type='application/ld+json']").each(function(){
            let text = $(this).text()
            const content = JSON.parse(text?text:'{}');
            ReactDOM.render(<Wrapper content={content} components={Components}/>, $(this).parent()[0])
        });
        $("script[type^='application/json+ry']").each(function(){
            let text = $(this).text()
            const view = $(this).attr('type').replace('application/json+ry', '')
            const content = JSON.parse(text?text:'{}');
            ReactDOM.render(<Ry class={view} content={content} components={Components}/>, $(this).parent()[0])
        });
    }
}

const theme = new AA19()

export const instance = theme

export const define = function(componentName, renderCallback){
    Components[componentName] = renderCallback
};

export const version = 1.0;