import blank from '../medias/images/blank.png';
import moment from 'moment';
import $ from 'jquery';
import numeral from 'numeral';
import 'bootstrap-datepicker';
import logo from '../medias/logo-dark.png';

window.jQuery = window.$ = $;

export const locale = $("html").attr("lang") ? $("html").attr("lang") : 'mg';

export const TCOUNTER = {
    AJAX : 0
}

numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});

numeral.register('locale', 'mg', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: 'Ar'
    }
});

export const DATES = {
    "dateTime": "%A, %e %B %Y г. %X",
    "date": "%d.%m.%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Alatsinainy", "Talata", "Alarobia", "Alakamisy", "Woma", "Sabotsy", "Alahady"],
    "shortDays": ["Alt", "Ta", "Alb", "Alk", "Zo", "Sa", "Alh"],
    months : ["Janoary", "Febroary", "Martsa", "Aprily", "Mey", "Jona", "Jolay", "Aogositra", "Septambra", "Oktobra", "Novambra", "Desambra"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "Mey", "Jon", "Jol", "Aog", "Sep", "Okt", "Nov", "Des"]
}

const body_initial_class = $("body").attr("class");

export const LOADINGSTART = (id)=>{
    $( "body" ).addClass(id);
    $( "body" ).addClass("ry-loading");
}

export const LOADINGEND = (id)=>{
    $("body").removeClass(id);
    if(/^\s*$/.test($('body').attr('class').replace("ry-loading", "").replace(body_initial_class, "")))
        $( "body" ).removeClass("ry-loading");
}

moment.locale(locale, {
    months : DATES.months,
    monthsShort : DATES.shortMonths,
    monthsParseExact : true,
    weekdays : 'alahady_alatsinainy_talata_alarobia_alakamisy_zoma_asabotsy'.split('_'),
    weekdaysShort : 'alh._alt._tal._alb._alk._zom._sab.'.split('_'),
    weekdaysMin : 'Ah_At_Ta_Ab_Ak_Zo_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Anio @] LT',
        nextDay : '[Rahampitso @] LT',
        nextWeek : 'dddd [@] LT',
        lastDay : '[Omaly t@] LT',
        lastWeek : 'dddd [teo t@] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : '@ %s',
        past : output=>{
			return output==='segondra' ? "vao izao" : '%s teo'.replace(/%s/i, output)
		},
        s : 'segondra',
        m : 'iray minitra',
        mm : '%d minitra',
        h : 'hadiny iray',
        hh : '%d ora',
        d : 'iray andro',
        dd : '%d andro',
        M : 'iray volana',
        MM : '%d volana',
        y : 'herintaona',
        yy : '%d taona'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

$.fn.datepicker.dates.mg = {
    days: ["alahady", "alatsinainy", "talata", "alarobia", "alakamisy", "zoma", "sabotsy"],
    daysShort: ["alh.", "alt.", "tal.", "alb.", "alk.", "zom.", "sab."],
    daysMin: ["ah", "at", "t", "ab", "ak", "z", "s"],
    months: ["janoary", "febroary", "martsa", "aprily", "mey", "jona", "jolay", "aogositra", "septambra", "oktobra", "novambra", "desambra"],
    monthsShort: ["jan.", "feb.", "mar.", "apr.", "mey", "jona", "jol.", "aog.", "sept.", "okt.", "nov.", "des."],
    today: "Anio",
    monthsTitle: "Volana",
    clear: "Fafao",
    weekStart: 1,
    format: "dd/mm/yyyy"
}

moment.locale(locale)

numeral.locale(locale)

export const siteSetup = $("#site-setup").length>0? JSON.parse($("#site-setup").text()) : {
    general : {
        logo : logo
    }
}

export default function trans(input, replaces={}) {
	let result = input
	if(('__' in window) && (input in __)) {
        result = __[input]
    }
    for(let repl in replaces) {
        let by = replaces[repl]
        let re = new RegExp(`:${repl}`, "g");
        result = result.replace(re, by)
    }
	return result;
}

export function plural(word, params, ifplural=null, ifnone=null){
    let number = 0
    if(typeof params == 'object')
        number = params.n
    else {
        number = params
        params = {n:number}
    }
    if(number==0 && ifnone) {
        return trans(ifnone, params)
    }
    const isPlural = (number>1)
    if(isPlural)
        return ifplural ? trans(ifplural, params) : `${trans(word, params)}s`
    return trans(word, params)
}

export const nophoto = siteSetup.general&&siteSetup.general.nophoto?(`/`+siteSetup.general.nophoto):blank

export const genders = {
    mr : trans('Atoa'),
    mrs : trans('Rtoa'),
    ms : trans('Rtoakely')
}
