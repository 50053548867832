import React, {Component} from 'react';
import trans, {nophoto} from 'ryapp/translations';
import moment from 'moment';
import $ from 'jquery';
import Modelizer from 'ryvendor/Ry/Core/Modelizer';
import {Img} from 'ryvendor/Ry/Core/Ry';

const delay = 60*5

class Timer extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            t : moment()
        }
    }

    componentDidMount() {
        const t0 = moment()
        this.end = moment(t0).add(delay, 's')
        this.timer = window.setInterval(()=>{
            if(this.timer && moment().isSameOrAfter(this.end)) {
                window.clearInterval(this.timer)
                if('onFinished' in this.props)
                    this.props.onFinished()
                this.props.store.dispatch({type:'investigate'})
            }
            this.setState({
                t : moment()
            })
        }, 0)
    }

    componentWillUnmount() {
        if(this.timer)
            window.clearInterval(this.timer)
    }

    render() {
        return <div className="text-info">
            {(this.end && this.end.diff(this.state.t)>0)?<React.Fragment>{trans("La durée moyenne d'une enquête préliminaire est d'environ :duration minutes.", {duration:delay/60})} 
                {trans('Patientez')} {moment.utc(this.end.diff(this.state.t)).format("HH:mm:ss")} {trans('ou refaites une recherche plus tard.')}
            </React.Fragment>:null}
        </div>
    }
}

class Counter extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            investigating : false,
            is_link : false,
            loading_link : false,
            meta : {}
        }
        this.investigate = this.investigate.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.search!=this.props.search && this.props.search.length>0) {
            this.setState({
                is_link : false,
                loading_link : true
            })
            if(/^https?:\/\/[\w\.]{3,}/.test(this.props.search)) {
                $.ajax({
                    url : '/ogparse',
                    data : {
                        url : this.props.search
                    },
                    success : response=>{
                        this.setState({
                            loading_link : false,
                            meta : response,
                            is_link : true
                        })
                    }
                })
            }
        }
    }

    investigate() {
        this.setState({
            investigating : true
        })
        this.props.store.dispatch({type:'investigate'})
    }

    render() {
        return <div>
            {(/^https?:\/\/[\w\.]{3,}/.test(this.props.search) && this.state.loading_link)?<div className="link-spinner"></div>:null}
            {this.state.is_link?<div className="card mx-2">
                <div className="card-body">
                    <div className="row" style={{fontSize:'80%',lineHeight:'normal'}}>
                        <div className="col-md-3">
                            <Img className="img-fluid" src={this.models('state.meta.image')} broken={nophoto}/>
                        </div>
                        <div className="col-md-9">
                            <strong>{this.models('state.meta.title')}</strong><br/>
                            {this.models('state.meta.description')}
                            <div className="align-items-center justify-content-between ml-0 mr-2 my-2 row">
                                {this.state.investigating?<Timer onFinished={this.investigate} store={this.props.store}/>:<React.Fragment>
                                    <button className="btn btn-danger" type="button" onClick={this.investigate}>{trans('Envoyer aux enquêteurs')}</button>
                                    <a href="/help?topic=investigation" target="_blank"><i className="fa fa-question-circle"></i></a>    
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>:(!/^https?:\/\/[\w\.]{3,}/.test(this.props.search)?<div className="mx-2"><span className="text-danger">{trans('Introuvable dans notre base.')} <span className="badge badge-pill badge-warning">{trans('ASTUCE')}</span> {trans("Copier et coller l'adresse URL de la page")}</span></div>:null)}
        </div>
    }
}

export default Modelizer(Counter);